const drawerWidth = 320;

const AppTheme = (theme) => ({
  rootContainer: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

export default AppTheme;
