import React from 'react';

import SignInBox from './app/sign-in';
import { Typography, Box } from '@mui/material';

const AppUnauthed = () => {
    return (<React.Fragment>
        <div style={ { minWidth: '100%', minHeight: 550, backgroundImage: 'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2)), url("banner.jpg")', backgroundPosition: 'bottom',  backgroundSize: 'cover' }}>
            <div style={ { display: 'flex', flexWrap: 'wrap' } }>
                <Box sx={ { flexGrow: 0, width: 500, maxWidth: '100%', justifyContent: 'right', display: { xs: 'none', lg: 'flex' } } }>
                    <img src="/front.png" />
                </Box>
                <div style={ { flexGrow: 0, width: 550, maxWidth: '100%', color: 'white', display: 'flex', flexDirection: 'column', textAlign: 'right' } }>
                    <Typography variant="h3" style={ { color: '#464646', width: 550, maxWidth: '100%', fontWeight: 600, lineHeight: '48px', marginTop: 72 } }>
                        Reliable Handyman Services in Cincinnati
                    </Typography>
                    <Typography variant="h4" style={ { color: '#464646', maxWidth: '100%', fontWeight: 400, fontSize: 28, marginTop: 8 } }>
                        Big or small, we fix it all!
                    </Typography>
                    <Typography variant="h4" style={ { color: '#000', maxWidth: '100%', fontWeight: 800, fontSize: 36, marginTop: 36, letterSpacing: '-2px' } }>
                        (513) 520-2047
                    </Typography>
                </div>
                <div style={ { flexGrow: 1 } } />
                <div style={ { flexGrow: 0, width: 300, maxWidth: '100%', justifyContent:"right", display: 'flex' } } />
            </div>
        </div>
        {/* <div>
        <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61558045020745&tabs=timeline&width=900&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=365658417256090"
                width="500"
                height="700"
                style={ { border:'none', overflow: 'hidden' } }
                scrolling="no"
                frameBorder="0" allowFullScreen={ true } allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div> */}
        <div>
            <SignInBox />
            
        </div>
    </React.Fragment>)
}

export default AppUnauthed;
