import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { initializeApp } from 'firebase/app';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import AppContext from './app-context';
import AppBar from './app/app-bar';
import AppAuthed from './app-authed';
import AppUnauthed from './app-unauthed';
import appTheme from './app-theme';

const useStyles = makeStyles(appTheme);

let firebaseApp;

const config = {
  apiKey: "AIzaSyBsoQXeqeUqNsFzggQYheaYFFmPdFRjPgQ",
  authDomain: "c-a-t-job-portal.firebaseapp.com",
  databaseURL: "https://c-a-t-job-portal-default-rtdb.firebaseio.com",
  projectId: "c-a-t-job-portal",
  storageBucket: "c-a-t-job-portal.appspot.com",
  messagingSenderId: "390538795524",
  appId: "1:390538795524:web:7b3fdb4112d97d4a8db351",
  measurementId: "G-VXNCVBRN5Z"
};

const App = () => {
  const [accessLevel, setAccessLevel] = useState(-2);
  const classes = useStyles();

  function registerUserAccessLevel(user) {
    if (user === null) { 
      setAccessLevel(0);
      return; 
    }

    get(ref(getDatabase(), `/users/${user.uid}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setAccessLevel(snapshot.val());
        } else {
          setAccessLevel(0);
        }
      });
  }

  useEffect(() => {
    firebaseApp = initializeApp(config);

    onAuthStateChanged(getAuth(), (user) => { registerUserAccessLevel(user); });
// need to unsubscribe too 
  }, []);

  const appContextValue = {
    accessLevel,
    setAccessLevel
  };

  return (
    <div className={classes.rootContainer}>
      <AppContext.Provider value={ appContextValue }>
        <AppBar authorized={ firebaseApp && getAuth().currentUser } />
        { firebaseApp && getAuth().currentUser && (<AppAuthed />)}
        { (!firebaseApp || !getAuth().currentUser) && (<AppUnauthed />) }
      </AppContext.Provider>
    </div>);
};

export default App;
