import React from 'react';
import { makeStyles } from '@mui/styles';
import AuthenticatedRoot from './app/pathing/authed-root';
import appTheme from './app-theme';

const useStyles = makeStyles(appTheme);

const AppAuthed = () => {
    const classes = useStyles();

    return (<React.Fragment>
        <main className={classes.content}>
          <AuthenticatedRoot />
        </main>
      </React.Fragment>);
};

export default AppAuthed;
